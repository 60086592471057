* {
   box-sizing: border-box;
 }

 html,

 .btn {
   background-color: #fff;
   border: none;
   outline: none;
   cursor: pointer;
   width: 230px;
   height: 47px;
   line-height: 47px;
   border-radius: 5px;
   box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.02);
   display: flex;
   padding: 0;
   transition: all 0.3s ease-in-out;
 }

 .btn:hover {
   background-color: #4664ff;
   color: #fff;
   transform: scale(1.02);
 }

 .btn i {
   flex: 0.2;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   font-size: 18px;
   border-right: 1px solid #ebe7fb;
 }

 .btn span {
   flex: 0.8;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 /* width */
 /* ::-webkit-scrollbar {
   width: 5px;
 } */

 /* Track */
 ::-webkit-scrollbar-track {
   background: #f1f1f1;
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: #3e3f41;
   border-radius: 50px;
 }

 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: #3e3f41;
 }

 /*===================== CHAT HEADER ===============*/

 .search_wrap {
   background-color: #e6e5ea;
   border-radius: 5px;
   align-items: center;
 }
 .search_wrap input {
   background-color: transparent;
   border: none;
   padding: 15px 15px;
   outline: none;
   width: 50%;
   padding-right: 0;
   border-radius: 5px;
   display: flex;
   align-items: center;
 }

 .addPerson {
   color: #fff;
   padding: 30 px;
 }

 @keyframes showIn {
   0% {
     transform: scale(0);
   }
   100% {
     transform: scale(1);
   }
 }

 /* resposive pagina login  */
 @media screen and (max-width: 959px) {
   .login-logo{
     display: none;
   }
 }
