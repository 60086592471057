.zero-cell {
    color: #ffffff; /* Texto blanco para celdas con valor 0 */
    background-color: #ff0000; /* Fondo rojo para celdas con valor 0 */
  }
  
  .low-cell {
    color: #000000; /* Texto negro para celdas con valor bajo */
    background-color: #ffff00; /* Fondo amarillo para celdas con valor bajo */
  }
  
  .medium-cell {
    color: #000000; /* Texto negro para celdas con valor medio */
    background-color: #00ff00; /* Fondo verde para celdas con valor medio */
  }
  
  .high-cell {
    color: #ffffff; /* Texto blanco para celdas con valor alto */
    background-color: #0000ff; /* Fondo azul para celdas con valor alto */
  }
  