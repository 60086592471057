/* poppins-300 - latin */
@font-face 
  font-family: 'Poppins'
  font-style: normal
  font-weight: 300
  src: url('../../assets/font/poppins/poppins-light/poppins-light.eot') /* IE9 Compat Modes */
  src: local(''),
       src:url('../../assets/font/poppins/poppins-light/poppins-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       src:url('../../assets/font/poppins/poppins-light/poppins-light.woff2') format('woff2'), /* Super Modern Browsers */
       src:url('../../assets/font/poppins/poppins-light/poppins-light.woff') format('woff'), /* Modern Browsers */
       src:url('../../assets/font/poppins/poppins-light/poppins-light.ttf') format('truetype'), /* Safari, Android, iOS */
       src:url('../../assets/font/poppins/poppins-light/poppins-light.svg#Poppins') format('svg') /* Legacy iOS */

/* poppins-regular - latin */
@font-face 
  font-family: 'Poppins'
  font-style: normal
  font-weight: 400
  src: url('../../assets/font/poppins/poppins-regular/poppins-regular.eot') /* IE9 Compat Modes */
  src: local(''),
       src:url('../../assets/font/poppins/poppins-regular/poppins-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       src:url('../../assets/font/poppins/poppins-regular/poppins-regular.woff2') format('woff2'), /* Super Modern Browsers */
       src:url('../../assets/font/poppins/poppins-regular/poppins-regular.woff') format('woff'), /* Modern Browsers */
       src:url('../../assets/font/poppins/poppins-regular/poppins-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       src:url('../../assets/font/poppins/poppins-regular/poppins-regular.svg#Poppins') format('svg') /* Legacy iOS */

/* poppins-700 - latin */
@font-face 
  font-family: 'Poppins'
  font-style: normal
  font-weight: 700
  src: url('../../assets/font/poppins/poppins-bold/poppins-bold.eot') /* IE9 Compat Modes */
  src: local(''),
      src: url('../../assets/font/poppins/poppins-bold/poppins-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      src: url('../../assets/font/poppins/poppins-bold/poppins-bold.woff2') format('woff2'), /* Super Modern Browsers */
      src: url('../../assets/font/poppins/poppins-bold/poppins-bold.woff') format('woff'), /* Modern Browsers */
      src: url('../../assets/font/poppins/poppins-bold/poppins-bold.ttf') format('truetype'), /* Safari, Android, iOS */
      src: url('../../assets/font/poppins/poppins-bold/poppins-bold.svg#Poppins') format('svg') /* Legacy iOS */
