
.formulario-button:hover{
   background: brown;
   color:'#000'
   }

   .p_title_form {
       font-weight: 600;
       padding-bottom: '20px';
       font-size:2rem;
   }
   .p_descripcion_form{
       padding-bottom: '20px';
       font-size: 1.5rem;
   }


   @media screen and (max-width: 600px) {
       .p_title_form {
           font-size: 1.2rem;
           padding-bottom: 10px;
       }
   }

   @media screen and (max-width: 600px) {
       .p_descripcion_form {
           font-size: 1rem;
           padding-bottom: 10px;
       }
   }


   .body_ {
       font-family: 'Arial', sans-serif;
       background-color: #f4f4f4;
       margin: 0;
       padding: 0;
       display: flex;
       align-items: center;
       justify-content: center;
       height: 100vh;
   }

   .maintenance-container {
       text-align: center;
       max-width: 400px;
       padding: 20px;
       background-color: #fff;
       border-radius: 8px;
       box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   }

   .maintenance-image {
       width: 'auto';
       max-width: 300px;
       height: auto;
       border-radius: 30%;
   }

   h1 {
       color: #333;
       margin-top: 20px;
   }

   p {
       color: #666;
       margin-bottom: 20px;
   }

   /* Agregar estilos adicionales según sea necesario */
   a {
       color: #3498db;
       text-decoration: none;
       font-weight: bold;
   }

   a:hover {
       text-decoration: underline;
   }
