@import "./../../../toolbox/styles/_color"

.c-button-outlined-small, .c-button-outlined-large
   display: flex
   justify-content: center
   background: $color-white
   padding: 10px 25px
   color: $color-blue
   font-size: 15px
   font-weight: 600
   font-family: sans-serif
   border: 1px solid $color-blue !important
   outline: none
   border-radius: 5px
   cursor: pointer
   transition: all 0.3s ease
   &:hover
      background: rgba($color-gray-soft ,0.5 )
   &:active
      background: rgba($color-blue ,0.5 )
   &:disabled
      color: rgba($color-black ,0.5)
      border-color: rgba($color-black ,0.5)
      &:active
         background: rgba($color-blue ,0 )

.c-button-outlined-large
   width: 100%

.c-button-contained
   background: $color-blue
   padding: 10px 25px
   color: $color-white
   font-size: 15px
   font-weight: 600
   font-family: sans-serif
   border: 1px solid $color-blue
   border-radius: 5px
   cursor: pointer
   transition: all 0.5s ease
   &:disabled
      background: rgba($color-gray, .5 )
      border-color: rgba($color-gray, 1)
      &:hover
         background: rgba($color-gray, .5 )
   &:hover
      background: rgba($color-blue ,0.8 )
   &:active
      background: rgba($color-blue ,0.5 )

.c-button-contained-action
   background: rgba($color-blue, 0.2)
   padding: 10px
   color: $color-blue
   font-size: 15px
   font-weight: 600
   font-family: sans-serif
   border: 1px solid $color-blue-ligth
   border-radius: 5px
   cursor: pointer
   transition: all 0.5s ease
   &:hover
      background: rgba($color-blue ,0.4 )
   &:active
      background: rgba($color-blue ,0.5 )

@media screen and ( min-width: 600px )
   .c-button-outlined-large
      padding: 15px
