
.formulario-button:hover{
background: brown;
color:'#000'
}

.wrapper{
    width:100%;
    height:100%;
    display:flex;
  }