/* -----------------------------------ON BOARDING ------------------------ */

.btn-import {
    font-family: "AvenirNextRounded";
    font-weight: "Demi";
    font-size: "13px";
    background-color: #FFFFFF !important;
    border: solid 1px #69ABC3 !important;
    color: #FFFFFF !important;
    cursor: pointer;
    transition: .5;
    text-transform: capitalize !important;
    min-width: 70px !important;
}
.btn-import:hover {
    background-color: #FFFFFF !important;
    border: 1px solid #516e80 !important;
    transform: scale(1.02);
    transition: .5;
}

.btn-dwtemplate{
    font-family: "AvenirNextRounded";
    font-weight: "Demi";
    font-size: "13px";
    background-color: #FFFFFF !important;
    border: solid 1px #69ABC3 !important;
    color: #FFFFFF !important;
    cursor: pointer;
    transition: .5;
    text-transform: capitalize !important;
    min-width: 40px !important;
}
.btn-dwtemplate:hover {
    background-color: #FFFFFF !important;
    border: 1px solid #516e80 !important;
    transform: scale(1.02);
    transition: .5;
}