$color-primary: #0D3152
$color-primary-light: #0B3F6E
// $color-primary-dark: #cccccc

$color-secondary: #FEC400
$color-secondary-light: #FEC40080
$color-accent: #269C9D

$color-accent-pale    : #77B637

$color-white          : #FFFFFF
$color-white-soft     : #F9F9FB
$color-black          : #000000

$color-gray           : #686868
$color-gray-4         : #4A4A49
$color-gray-pale      : #CCCCCC
$color-gray-soft      : #EAEAEA
$color-gray-light     : #e0e0e0
$color-main           : #004A8C
$color-blue           : #18617c
$color-finished       : #ff98005c
$color-blue-ligth     : #117ba1
$color-border-tabs    : #f3f0f0
$color-text-dark      : #313131

$color-text-red       : #ff0000
$color-text-accent: #FFFFFF

$color-error: #FF5252
$color-success: #4CAF50
$color-disabled: rgba(0, 0, 0, 0.38)
$color-campaing: #313131
$color-llamada: #004A8C
$item-correo: #17a2b8
$color-conferencia: #FF5252
$color-otro: #4CAF50

html:root
   --color_bg_menu: rgba(0,0,0,0.5)
   --color_bg_spinner: #333
   --color-main-page: #f9f9f9
   --color-bg-button: #efefef
   --color-text-dark: #313131

   --shadow-bg-menu: rgba(0,0,0,0.25)
   --gray-normal-color: #8e8e8e
   --gray-soft-color: #F5F4F7
   --item-menu-project: #ccdbe8

   --item-menu-selected: #E0F2EB
   --item-menu-collapse: #F3F1FD
   --item-menu-letter: #17a2b8
   --item-menu-default: #686868
   --item-menu-icon: #313131

   --color-white: #ffffff
   --color-black: black
   --color-main: #004A8C

   --color-text-dark: #313131

