.file-select {
   position: relative;
   display: inline-block;
 }

 .file-select::before {
   background-color: #155172;
   color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 3px;
   content: 'Seleccionar'; /* testo por defecto */
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   width: 135%;
 }

 .file-select input[type="file"] {
   opacity: 0;
   width: 200px;
   height: 32px;
   display: inline-block;
 }

 #src-file1::before {

   content: 'Agregar imagen';
 }


 .wrapper{

   width:100%;
   height:100%;
   display:flex;
 }

   .file-upload{

     height:100px;
     width:100px;
     border-radius: 20px;
     position:relative;
     display:flex;
     justify-content:center;
     align-items: center;
     border:4px solid #F3F3F3;
     overflow:hidden;
     background-image: linear-gradient(to bottom, #155172 50%, #F3F3F3 50%);
     background-size: 100% 200%;
     transition: all 1s;
     color: #F3F3F3;
     font-size:100px;
   }
   .file-upload input[type='file']{
       height:200px;
       width:200px;
       position:absolute;
       top:0;
       left:0;
       opacity:0;
       cursor:pointer;
     }

     .file-upload:hover{
       background-position: 0 -100%;
       color:#155172;
     }


