@import "../../../toolbox/styles/_color"
.c-dialog-confirm-continer
   border-radius: 10px !important
   box-shadow: -1px 6px 8px -7px rgba($color-black,0.2), 0px 12px 20px 3px rgba($color-black,0.14) !important

.c-dialog-confirm-title
   text-align: center
   font-size: 48px
   color: $color-black
   font-weight: bold
   line-height: 20px
   font-family: sans-serif

.c-dialog-confirm-message
   p
      text-align: center
      line-height: 30px
      font-size: 16px
      font-weight: 600

.c-dialog-confirm-action
   padding: 20px 24px !important
   display: flex
   justify-content: space-around !important
   button
      margin-top: 0 !important
